import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import "./path.css"
import AudioPlayer from "../audio/AudioPlayer";
import ContactUs from "../contact/ContactUs";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import Disclaimer from "../desclaimer/Desclaimer";

  
const outcomeAlerts = {
  11: {
    // title: "You Escape the Palace",
    title: "With clever choices, you slip from Dracula's grasp, but the dread remains in your soul",
    text: "Congratulations, you have made it out.",
    iconHtml: "✨",
    confirmButtonText: "Continue",
    customClass: {
      popup: 'custom-popup',
      title: 'custom-title',
      confirmButton: 'custom-confirm-button',
    },
  },
  12: {
    // title: "You Are Dead",
    title: "A single misstep seals your fate-your journey ends in the cold grip of death",
    text: "You met a tragic end. Game over.",
    iconHtml: "💀",
    confirmButtonText: "Try Again",
    customClass: {
      popup: 'custom-popup-dead',
      title: 'custom-title',
      confirmButton: 'custom-confirm-button-dead',
    },
  },
  13: {
    // title: "You become a Vampire serving Dracula",
    title: "Dracula's bite seals your fate-you are now a vampire, bound to serve him for eternity",
    text: "Embrace the darkness. You belong to Dracula now.",
    iconHtml: "🦇",
    confirmButtonText: "Accept Fate",
    customClass: {
      popup: 'custom-popup-vampire',
      title: 'custom-title',
      confirmButton: 'custom-confirm-button-vampire',
    },
  },
  14: {
    // title: "You are to live in the palace as Dracula's servant for eternity",
    title: "You've been spared, but now your eternity belongs to Dracula's will",
    text: "You are bound to this place forever, serving Dracula.",
    iconHtml: "👻",
    confirmButtonText: "Accept Fate",
    // backdrop: `rgba(0, 0, 0, 0.9) url("https://media.giphy.com/media/3oEjHKjPmOxEAuAuQo/giphy.gif") center left no-repeat`,
    customClass: {
      popup: 'custom-popup-servant',
      title: 'custom-title',
      confirmButton: 'custom-confirm-button-servant',
    },
  },
};




const Path = ({ pathData, onPathEnd, onReadAboutPath, onReturnToPathList }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isPathComplete, setIsPathComplete] = useState(false);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false); // New state for Disclaimer

  useEffect(() => {
    setCurrentQuestionIndex(0);
    setIsPathComplete(false);
  }, [pathData]);

  const handleOptionClick = (nextIndex, outcomes) => {  
    if (Array.isArray(outcomes) && outcomes.length > 0) {
      // Use a function to show alerts sequentially
      const showAlertsSequentially = (index) => {
        if (index < outcomes.length) {
          const outcome = outcomes[index];
          const alertConfig = outcomeAlerts[outcome];
          if (alertConfig) {
            Swal.fire(alertConfig).then(() => {
              // Recursively call the next alert in the outcomes array
              showAlertsSequentially(index + 1);
            });
          }
        } else {
          // Once all alerts are shown, move to the next question
          if (nextIndex >= 0 && nextIndex < pathData.questions.length) {
            setCurrentQuestionIndex(nextIndex);
          } else {
            setIsPathComplete(true);
          }
        }
      };
  
      // Start showing alerts from the first one
      showAlertsSequentially(0);
    } else {
      // Handle when no outcomes are provided or they are empty
      if (nextIndex >= 0 && nextIndex < pathData.questions.length) {
        setCurrentQuestionIndex(nextIndex);
      } else {
        setIsPathComplete(true);
      }
    }
  };
  


  const restartPath = () => {
    setCurrentQuestionIndex(0);
    setIsPathComplete(false);
  };

  const toggleContactUs = () => {
    setIsContactUsOpen(!isContactUsOpen);
    setIsPrivacyPolicyOpen(false);
    setIsDisclaimerOpen(false); // Close disclaimer if contact us is opened
  };

  const handleSubmitSuccess = () => {
    Swal.fire({
      title: 'Success!',
      text: 'Your message has been sent successfully.',
      icon: 'success',
      confirmButtonText: 'OK'
    }).then(() => {
      setIsContactUsOpen(false); // Close the contact form after success message
    });
  };

  const togglePrivacyPolicy = () => {
    setIsPrivacyPolicyOpen(!isPrivacyPolicyOpen);
    setIsContactUsOpen(false); // Close contact form if privacy policy is opened
    setIsDisclaimerOpen(false); // Close disclaimer if privacy policy is opened
  };

  const toggleDisclaimer = () => {
    setIsDisclaimerOpen(!isDisclaimerOpen);
    setIsContactUsOpen(false); // Close contact form if disclaimer is opened
    setIsPrivacyPolicyOpen(false); // Close privacy policy if disclaimer is opened
  };

  const currentQuestion = pathData.questions[currentQuestionIndex];

  return (
    <div className="main-container">
      {/* Separate container for Contact Us and AudioPlayer */}
      <div className="top-container">
        <AudioPlayer />
        <button className="contact-button" onClick={toggleContactUs}>
          Contact Us
        </button>
      </div>

      {isContactUsOpen && (
        <div className="contact-overlay">
          <div className="contact-popup">
            <ContactUs onClose={toggleContactUs} onSubmit={handleSubmitSuccess} />
            <button className="close-button" onClick={toggleContactUs}>Close</button>
          </div>
        </div>
      )}

      {/* Path Container */}
      <div className="path-container animate__animated animate__fadeIn">
        <h2 id="head">{pathData.title}</h2>

        <button className="read-about-button" onClick={onReadAboutPath}>
          READ ABOUT THE PATH
        </button>

        {isPathComplete ? (
          <div className="outcome">
            <p>You have reached the end of this path.</p>
            <button className="restart-button" onClick={restartPath}>
              Restart Path
            </button>
            <button className="next-button" onClick={onPathEnd}>
              Next Path
            </button>
            {/* New All Paths Button */}
            <button className="all-paths-button" onClick={onReturnToPathList}>
              Go back to all paths
            </button>
          </div>
        ) : (
          <div>
            <h3 id="head2">{currentQuestion.text}</h3>
            <div className="options">
              {currentQuestion.options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleOptionClick(option.next, option.outcomes)}
                  className="option-button"
                >
                  {option.text}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Privacy Policy and Disclaimer buttons side by side */}
      <div className="policy-buttons">
        <button className="privacy-policy-button" onClick={togglePrivacyPolicy}>
          Privacy Policy
        </button>
        <button className="disclaimer-button" onClick={toggleDisclaimer}>
          Disclaimer
        </button>
      </div>

      {isPrivacyPolicyOpen && <PrivacyPolicy onClose={togglePrivacyPolicy} />}
      {isDisclaimerOpen && <Disclaimer onClose={toggleDisclaimer} />} {/* Add Disclaimer component here */}
    </div>
  );
};

export default Path;
